const DetailBox = ({ title, value }) => {
  return (
    <>
      <div className="flex min-w-full flex-1 flex-col gap-2 rounded-xl p-6 border border-[#344d65]">
        <p className="text-white text-base font-medium leading-normal">
          {title}
        </p>
        <p className="text-white tracking-light text-2xl font-bold leading-tight">
          {value}
        </p>
      </div>
    </>
  );
};

export default DetailBox;
