const RecentActivity = () => {
  return (
    <div>
      <h2 className="text-white text-[22px] font-bold leading-tight tracking-[-0.015em]">
        Recent Activity
      </h2>
      <div className="flex items-center gap-4 bg-[#111a22] min-h-[72px] py-2 justify-between">
        <div className="flex flex-col justify-center">
          <p className="text-white text-base font-medium leading-normal line-clamp-1">
            Coupon Shared
          </p>
          <p className="text-[#93adc8] text-sm font-normal leading-normal line-clamp-2">
            Coupon shared with John Doe
          </p>
        </div>
        <div className="shrink-0">
          <p className="text-[#93adc8] text-sm font-normal leading-normal">
            2d ago
          </p>
        </div>
      </div>
      <div className="flex items-center gap-4 bg-[#111a22]   min-h-[72px] py-2 justify-between">
        <div className="flex flex-col justify-center">
          <p className="text-white text-base font-medium leading-normal line-clamp-1">
            Transaction
          </p>
          <p className="text-[#93adc8] text-sm font-normal leading-normal line-clamp-2">
            $10 transaction with John Doe
          </p>
        </div>
        <div className="shrink-0">
          <p className="text-[#93adc8] text-sm font-normal leading-normal">
            Just now
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecentActivity;
