import React from 'react';
import QuickAction from './quickAction';
import DetailBox from './detailBox';
import RecentActivity from './recentActivity';
import AnalyticsGraph from './analyticsGraph';
import Header from './header';
import StoreDropdown from './storeDropdown';

const Analytics = () => {
  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111a22] dark justify-between group/design-root overflow-x-hidden"
      style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}
    >
      <div className="p-4 @container">
        <Header />
      </div>

      <div className="p-4 @container">
        <StoreDropdown options={[1, 2]} />
      </div>

      <div className="p-4 @container">
        <div className="grid grid-cols-4 md:grid-cols-3 gap-4">
          <div className="col-span-4 md:col-span-1">
            <DetailBox title="Total Earnings" value="$3,200" />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DetailBox title="Earnings from Sharing" value="$1,500" />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DetailBox title="Earnings from Redemptions" value="$1,700" />
          </div>
        </div>
      </div>

      <div className="p-4 @container">
        <QuickAction />
      </div>

      <div className="p-4 @container">
        <h2 className="text-white text-[22px] font-bold leading-tight tracking-[-0.015em] pb-3">
          Campaign Performance
        </h2>

        <div className="flex flex-wrap gap-4">
          <div className="flex-1 flex-col gap-2">
            <DetailBox title="Coupons Shared" value="44" />
          </div>
          <div className="flex-1 flex-col gap-2">
            <DetailBox title="Coupons Redeemed" value="10" />
          </div>
        </div>
      </div>

      <div className="p-4 @container">
        <AnalyticsGraph />
      </div>

      <div className="p-4 @container">
        <RecentActivity />
      </div>
    </div>
  );
};

export default Analytics;
