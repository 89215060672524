import * as yup from 'yup';

export const bankInitialState = {
  id: '',
  panNumber: '',
  accountHolderName: '',
  accountNumber: '',
  bankName: '',
  ifscCode: '',
  kycDocument: '',
};
export const businessDetailInitial = {
  exempted: true,
  number: '',
  state: '',
  url: '',
};

export const bankSchema = yup.object().shape({
  panNumber: yup.string().required('This field is mandatory'),
  accountHolderName: yup.string().required('This field is mandatory'),
  accountNumber: yup
    .number()
    .typeError('AccountNumber must be a number')
    .required('This field is mandatory'),
  bankName: yup.string().required('This field is mandatory'),
  ifscCode: yup.string().required('This field is mandatory'),
  kycDocument: yup.string().required('This field is mandatory'),
});
export const bussinessSchema = yup.object().shape({
  exempted: yup.boolean(),
  state: yup.string().when('exempted', {
    is: false,
    then: yup.string().required('This field is mandatory'),
  }),
  number: yup.string().when('exempted', {
    is: false,
    then: yup.string().required('This field is mandatory'),
  }),
  url: yup.string().when('exempted', {
    is: false,
    then: yup.string().required('This field is mandatory'),
  }),
});

const BankInputArray = [
  {
    lable: 'ACCOUNT NUMBER',
    name: 'accountNumber',
    placeholder: 'ENTER ACCOUNT NUMBER',
  },
  {
    lable: 'PAN NUMBER',
    name: 'panNumber',
    placeholder: 'ENTER PAN NUMBER',
  },
  {
    lable: 'ACCOUNT HOLDER',
    name: 'accountHolderName',
    placeholder: 'ENTER  NAME',
  },
  {
    lable: 'BANK NAME',
    name: 'bankName',
    placeholder: 'ENTER BANK NAME',
  },
  {
    lable: 'IFSC CODE',
    name: 'bankName',
    placeholder: 'ENTER IFSC CODE',
  },
];
export const businessInputArray = [
  {
    grid: true,
    items: [
      {
        type: 'select',
        disabled: false,
        inputType: '',
        lable: ' STATE',
        name: 'state',
        placeholder: 'Select state ',
        listData: ['Tamil Nadu', 'Andhra Pradesh'],
        grid: true,
        xs: 12,
        md: null,
      },
      {
        type: 'input',
        disabled: false,
        inputType: 'text',
        lable: 'GST IDENTIFICATION NUMBER',
        name: 'number',
        placeholder: 'Enter branch name',
        grid: true,
        xs: 12,
        md: null,
      },
      {
        type: 'fileUpload',
        id: 'gstDocument',
        name: 'url',
        label: 'Upload Store Image',
        xs: 12,
      },
    ],
  },
];
