import React, { useState, useRef, useEffect } from 'react';

const StoreDropdown = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef(null);
  const [dropdownWidth, setDropdownWidth] = useState('auto');

  useEffect(() => {
    const updateDropdownWidth = () => {
      if (dropdownRef.current) {
        setDropdownWidth(`${dropdownRef.current.offsetWidth}px`);
      }
    };

    updateDropdownWidth();
    window.addEventListener('resize', updateDropdownWidth);

    return () => {
      window.removeEventListener('resize', updateDropdownWidth);
    };
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <>
      <div ref={dropdownRef}>
        <button
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-[#344d65] shadow-sm px-4 py-2 bg-[#1a2b3c] text-sm font-medium text-white hover:bg-[#2a3b4c] focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption}
          <svg
            className="-mr-1 ml-2 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute mt-2 rounded-md shadow-lg bg-[#1a2b3c] ring-1 ring-black ring-opacity-5"
          style={{ width: dropdownWidth }}
        >
          <div className="py-1">
            {options.map((option) => (
              <button
                key={option}
                className="block px-4 py-2 text-sm text-white hover:bg-[#2a3b4c] w-full text-left"
                onClick={() => handleSelect(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default StoreDropdown;
