import React from 'react';

const QuickAction = () => {
  return (
    <>
      <h2 className="text-white text-[22px] font-bold leading-tight tracking-[-0.015em] pb-3 pt-5">
        Quick Actions
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <button className="flex items-center gap-4 bg-[#1980e6] text-black px-4 min-h-14 justify-between rounded-lg w-full">
          <p className="text-white text-base font-normal leading-normal flex-1 truncate">
            Create Campaign
          </p>
        </button>
        <button className="flex items-center gap-4 bg-white text-black px-4 min-h-14 justify-between rounded-lg w-full">
          <p className="text-black text-base font-normal leading-normal flex-1 truncate">
            Share a Coupon
          </p>
        </button>
        <button className="flex items-center gap-4 bg-white text-black px-4 min-h-14 justify-between rounded-lg w-full">
          <p className="text-black text-base font-normal leading-normal flex-1 truncate">
            Redeem a Coupon
          </p>
        </button>
      </div>
    </>
  );
};

export default QuickAction;
