import * as yup from 'yup';
const states = ['Tamil Nadu', 'Andhra Pradesh'];
const cities = [
  'Chennai',
  'Coimbatore',
  'Madurai',
  'Tiruchirappalli',
  'Salem',
  'Tirunelveli',
  'Tiruppur',
  'Vellore',
  'Erode',
  'Thoothukkudi',
  'Dindigul',
  'Thanjavur',
  'Ranipet',
  'Sivakasi',
  'Karur',
  'Udhagamandalam',
  'Hosur',
  'Nagercoil',
  'Kanchipuram',
  'Kumarapalayam',
  'Karaikkudi',
  'Neyveli',
  'Cuddalore',
  'Kumbakonam',
  'Tiruvannamalai',
  'Pollachi',
  'Rajapalayam',
  'Gudiyatham',
  'Pudukkottai',
  'Vaniyambadi',
  'Ambur',
  'Nagapattinam',
  'Srikakulam',
];
export const storeFormArray = [
  {
    grid: true,
    items: [
      {
        type: 'input',
        disabled: false,
        inputType: 'text',
        lable: 'BRANCH NAME*',
        name: 'branchName',
        placeholder: 'Enter branch name',
        grid: true,
        xs: 12,
        md: null,
      },
    ],
  },
  {
    grid: true,
    items: [
      {
        type: 'select',
        disabled: false,
        inputType: '',
        lable: 'SELECT A CATEGORY*',
        name: 'category',
        placeholder: 'Select category ',
        listData: [],
        grid: true,
        xs: 12,
        md: null,
      },
    ],
  },
  {
    grid: true,
    items: [
      {
        type: 'input',
        disabled: false,
        inputType: 'number',
        lable: 'NUMBER OF BILLS PER DAY*',
        name: 'numberOfWalkIns',
        placeholder: 'Enter no of bills per day ',
        grid: true,
        xs: 12,
        md: 4,
      },
      {
        type: 'input',
        disabled: false,
        inputType: 'number',
        lable: 'AVERAGE BILL VALUE*',
        name: 'averageBillValue',
        placeholder: 'Enter average bill value',
        grid: true,
        xs: 12,
        md: 4,
      },
    ],
  },
  {
    grid: false,
    lable: 'OUTLET ADDRESS',
  },

  {
    grid: true,
    items: [
      {
        type: 'input',
        disabled: false,
        inputType: 'number',
        lable: 'PIN CODE*',
        name: 'address.zipcode',
        nestedObj: true,
        placeholder: 'Enter pincode',
        grid: true,
        xs: 12,
        md: 4,
      },
      {
        type: 'select',
        listData: states,
        disabled: false,
        inputType: '',
        lable: 'STATE*',
        name: 'address.state',
        placeholder: 'Select state ',
        grid: true,
        nestedObj: true,
        xs: 12,
        md: 4,
      },
      {
        type: 'select',
        disabled: false,
        listData: cities,
        inputType: '',
        lable: 'CITY*',
        name: 'address.city',
        placeholder: 'Select state ',
        grid: true,
        nestedObj: true,
        xs: 12,
        md: 4,
      },
    ],
  },
  {
    grid: true,
    items: [
      {
        type: 'input',
        disabled: false,
        checkBox: true,
        checBoxLable: 'Same as Bussiness owners email',
        inputType: 'email',
        lable: 'STORE EMAIL*',
        name: 'email',
        placeholder: 'Enter store email',
        grid: true,
        xs: 12,
        md: 6,
      },

      {
        type: 'phone',
        disabled: false,
        checkBox: true,
        checBoxLable: 'Same as bussiness owners phone number',
        inputType: '',
        lable: 'CUSTOMER CARE CONTACT*',
        name: 'contactNumber',
        placeholder: 'Enter phone number ',
        grid: true,
        xs: 12,
        md: 6,
      },
    ],
  },
  {
    grid: true,
    items: [
      {
        type: 'fileUpload',
        id: 'storeImage',
        name: 'imageUrl',
        label: 'Upload Store Image*',
        xs: 12,
        md: 6,
      },

      {
        type: 'fileUpload',
        id: 'storeLogo',
        name: 'logoUrl',
        label: 'Upload Logo*',
        xs: 12,
        md: 6,
      },
    ],
  },

  {
    grid: false,
    lable: 'HOURS OF OPERATION*',
  },
];
export const storeInitialState = {
  branchName: '',
  category: '',
  locality: '',
  address: { street: '', state: '', city: '', zipcode: '' },
  email: '',
  contactNumber: '',
  customerCareNumber: '',
  numberOfWalkIns: 0,
  averageBillValue: 0,
  imageUrl: '',
  logoUrl: '',
  workingHours: [],
  id: '',
  type: 'chain',
};

export const storesSchema = yup.object().shape({
  branchName: yup.string().required('This field is mandatory'),
  category: yup.string().required('This field is mandatory'),
  locality: yup.string(),
  address: yup.object().shape({
    street: yup.string(),
    state: yup.string().required('This field is mandatory'),
    city: yup.string().required('This field is mandatory'),
    zipcode: yup.string().required('This field is mandatory'),
  }),
  email: yup.string().email().required('This field is mandatory'),
  contactNumber: yup.string().required('This field is mandatory'),
  numberOfWalkIns: yup
    .number()
    .typeError('This field must be a number')
    .positive()
    .min(1, 'Must be greater than or equal to 1')
    .required('This field is mandatory'),
  averageBillValue: yup
    .number()
    .typeError('This field must be a number')
    .positive()
    .min(1, 'Must be greater than or equal to 1')
    .required('This field is mandatory'),
  imageUrl: yup.string(),
  logoUrl: yup.string(),
  workingHours: yup.array().test({
    message: 'working hours is mandatory',
    test: (arr) => arr.length > 0,
  }),
  id: yup.string(),
  type: yup.string().required('This field is mandatory'),
});

export const initialState = {
  businessName: '',
  businessOwnerFirstName: '',
  businessOwnerLastName: '',
  businessOwnerEmail: '',
  referralCode: '',
};

export const hoursList = [
  '01:00 am',
  '02:00 am',
  '03:00 am',
  '04:00 am',
  '05:00 am',
  '06:00 am',
  '07:00 am',
  '08.00 am',
  '09:00 am',
  '10:00 am',
  '11:00 am',
  '12:00 pm',
  '13:00 pm',
  '14:00 pm',
  '15:00 pm',
  '16:00 pm',
  '17:00 pm',
  '18:00 pm',
  '19:00 pm',
  '20:00 pm',
  '21:00 pm',
  '22:00 pm',
  '23:00 pm',
  '24:00 am',
];
